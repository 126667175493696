import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Animated GIFs",
  "path": "/pickgifscreen.html"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Please select one of these links to see the GIF. If you want to keep it. Right mouse click it and "Save Picture As" or "Save Target As".`}</p>
    <p><a parentName="p" {...{
        "href": "goku_cell.gif.html"
      }}>{`SSJ Gokou and Perfect Cell doing a Kamehamehas at each other`}</a>{`
`}<a parentName="p" {...{
        "href": "gotentrunks.gif.html"
      }}>{`SSJ Goten and SSJ Chibi Trunks powering up`}</a>{`
`}<a parentName="p" {...{
        "href": "trunks_cell.gif.html"
      }}>{`SSJ Trunks slicing Imperfect Cell in half`}</a>{`
`}<a parentName="p" {...{
        "href": "Gotenks.gif.html"
      }}>{`SSJ3 Gotenks shooting energy blasts`}</a>{`
`}<a parentName="p" {...{
        "href": "cannon.gif.html"
      }}>{`Piccolo doing SpecialBeamCannon`}</a>{`
`}<a parentName="p" {...{
        "href": "Fusion.gif.html"
      }}>{`SSJ Goten and SSJ Chibi Trunks fusing to Super Gotenks then going SSJ3`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      